@mixin display-table {
  display: table;

  > div {
    display: table-cell;
  }
}

@mixin table-cell-center {
  text-align: center;
  vertical-align: middle;
}

@mixin display-block-center {
  display: block;
  margin: 0 auto;
}

@mixin pseudo($display: block, $position: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $position;
}
