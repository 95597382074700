// Bottom align container has to work with bottom align item.
// Container is for parent and item is the exact div you want to bottom aligned

@mixin bottom-align-container {
  position: relative;
  vertical-align: bottom;
}

@mixin bottom-align-item {
  bottom: 0;
  position: absolute;
}

// Horizontal layout is for container to allow children in horizontally
// rather than stacking from up down

@mixin horizontal-layout {
  display: inline-block;
  position: relative;

  div {
    display: inline-block;
  }
}
