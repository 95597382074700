@import '~#/theme/main';

.query-text {
  @include title-font-size;

  color: $main-highlight-color;
}

.outer-container {
  border-radius: vw(2px);
  padding-top: vw(20px);
  transform: none;
}

.inner-container {
  margin-bottom: -8vh;
  width: vw(1880px);

  &.ltr {
    padding: vw(12px) 0 vw(40px) vw(75px);
  }

  &.rtl {
    padding: vw(12px) vw(75px) vw(40px) 0;
  }
}

.container-title {
  color: var(--coloronbackground);
  font-size: vw(40px);
  font-weight: 500;
  height: vw(44px);
  line-height: normal;
  margin-top: 8vh;
  padding: vw(40px) vw(87px) vw(5px);
}

.elevate-channels {
  padding-top: 0;

  &.outer-container {
    margin: auto;
    padding-bottom: vw(20px);
    padding-top: 0;
    width: 100%;
  }

  .inner-container {
    display: block;
    left: 0;
    padding: vw(100px) 0 0 0;
    padding-bottom: 0;
    right: 0;
  }
}

// TODO also affects Movies, should find fix!
.hero-vertical,
.elevate-hero-banner {
  &.outer-container {
    margin: auto;
    margin-bottom: -8vh;
    padding-bottom: 45%; // aspect ratio 16x6
    padding-top: 0;
    position: relative;
    width: 100%;
  }

  .container-title {
    display: none;
  }

  .inner-container {
    bottom: 0;
    display: block;
    left: 0;
    padding: 0;
    padding-bottom: vw(30px);
    position: absolute;
    right: 0;
    top: 0;
  }
}
