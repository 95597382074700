@import '~#/theme/main';

@import '~#/theme/base';

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  align-items: center;
  background-color: var(--backgroundlow);
  display: flex;
  height: vw(1080px);
  justify-content: center;
  position: absolute;
  width: vw(1920px);
  z-index: 10;

  .loader-icon {
    animation: spin 2s linear infinite;
    width: 200px;
  }
}
