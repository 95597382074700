@import '~#/theme/main';

.item {
  align-items: center;
  display: flex;

  button {
    @include default-button;

    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: var(--coloronaccent);
    cursor: pointer;
    display: flex;
    font-family: Outfit, sans-serif;
    font-size: vw(22px);
    font-style: normal;
    font-weight: 400;
    height: vw(64px);
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.primary {
  button {
    display: flex;
    height: vw(64px);
    justify-content: center;
    min-width: vw(192px);
    padding: vw(18px) vw(36px);

    svg {
      height: vw(30px);
      margin-right: vw(8px);
      width: vw(30px);
    }
  }
}

.secondary {
  .icon {
    button > div {
      height: vw(38px);
    }
  }

  button {
    display: flex;
    justify-content: center;
    width: vw(64px);

    svg {
      height: vw(38px);
      width: vw(38px);
    }
  }

  &.eco-profile {
    button {
      width: vw(205px);

      svg {
        width: vw(137px);
      }
    }
  }

  &.eco-profile {
    button {
      width: vw(205px);

      svg {
        width: vw(137px);
      }
    }
  }
}

.icon {
  height: fit-content;
  display: flex;
  justify-content: center;

  svg {
    height: vw(45px);

    path {
      fill: var(--coloronbackground);
    }
  }
}

.selected {
  .default {
    button {
      background: var(--alternativebackgroundmedium);
      border-radius: vw(70px);
      color: var(--coloronaccent);
    }
  }
}

.focused {
  .default {
    button {
      background-color: var(--accent);
      border-radius: vw(70px);
      color: var(--coloronaccent);
      height: vw(64px);
    }
  }

  .icon {
    button {
      background-color: var(--accent);
      border-radius: vw(70px);
      color: var(--coloronaccent);
      height: vw(64px);
    }
  }
}
