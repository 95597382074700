@import '~#/theme/main';

.button {
  background-color: var(--alternativebackgroundmedium);
  border: vw(1px) solid transparent;
  border-radius: vw(16px);
  color: var(--coloronbackground);
  font-size: vw(24px);
  font-weight: 400;
  height: vw(66px);

  svg {
    path {
      fill: var(--coloronaccent);
    }
  }

  @include browser($legacy-devices) {
    /* stylelint-disable */
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    /* stylelint-enable */
    border-radius: 12px !important;
  }

  &.selected {
    background-color: $lightgrey;
  }

  &.focused {
    background-color: var(--accent);
    color: var(--coloronaccent);

    svg {
      path {
        fill: var(--coloronaccent);
      }
    }

    @include browser($legacy-devices) {
      /* stylelint-disable */
      border-width: 2px;
      border-style: solid;
      border-color: var(--accent);
      /* stylelint-enable */
    }
  }

  & span {
    font-family: Outfit, sans-serif;
    align-items: center;
    display: flex !important;
    justify-content: center;
    padding: vw(15px) vw(20px);
  }
}
