@import '~#/theme/vw';

@import '~#/theme/main';

.notification-group {
  display: flex;
  flex-flow: column;
  position: fixed;
  width: vw(1024px);
  z-index: 10;
}

.topstart {
  left: vw(24px);
  top: vw(24px);

  &.rtl {
    left: auto;
    right: vw(24px);
  }
}

.topend {
  right: vw(24px);
  top: vw(24px);

  &.rtl {
    left: vw(24px);
    right: auto;
  }
}

.bottomstart {
  bottom: vw(24px);
  left: vw(24px);

  &.rtl {
    left: auto;
    right: vw(24px);
  }
}

.bottomend {
  bottom: vw(24px);
  right: vw(24px);

  &.rtl {
    left: vw(24px);
    right: auto;
  }
}

.up {
  flex-direction: column-reverse;
}

.down {
  flex-direction: column;
}

.notification {
  padding: vw(5px);
}
