@import '~#/theme/main';

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: vw(200px);
  height: vw(200px);

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;

    &.gradient-bg {
      background: linear-gradient(blue, pink);
    }
  }
}

.name {
  align-items: center;
  background-color: rgba(242, 243, 244, 0.2);
  color: rgba(242, 243, 244, 0.8);
  display: flex;
  font-size: vw(36px);
  font-style: normal;
  font-weight: 500;
  justify-content: center;
}

.focused {
  animation: highlight 0.3s forwards;
  border: vw(4px) solid var(--accent);
}
