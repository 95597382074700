@keyframes profilelist {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes digitfocus {
  0% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}
