@import '~#/theme/main';

$description-bg-color: rgba($rgb-235, .15);

.episode {
  display: inline-block;
  overflow: hidden;

  @include browser($legacy-devices) {
    border-radius: 15px !important;
    width: vw(570px);
  }

  .container-image {
    border: vw(3px) solid transparent;
    border-radius: vw(14px);
    overflow: hidden;
    position: relative;

    &.container-image-focused {
      border: vw(3px) solid var(--accent);
      border-radius: vw(14px);
    }

    .cover-image {
      border-radius: vw(12px);
    }
  }

  .progress {
    background-color: rgba($light-grey, .5);
    bottom: 0;
    height: vw(12px);
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 1;

    > span {
      background-color: $white-inner-bar;
      display: block;
      height: 100%;
    }
  }

  .title {
    bottom: vw(5.5px);
    color: $rgb-235;
    font-size: vw(30px);
    line-height: vw(32px);
    padding: vw(19.2px) 0;
    position: relative;
    width: 93.5%;

    @include browser($legacy-devices) {
      width: vw(531px);
    }
  }
}
