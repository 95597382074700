@import '~#/theme/vw';

@import '~#/theme/colors';

.player-trailer-container {
  position: absolute;

  &.hide {
    display: none;
  }
}

.bitmovin-player {
  display: flex;
  justify-content: end;
  overflow: hidden;
  width: vw(1920px);
  z-index: -1;

  & video {
    height: vw(1080px);
    width: vw(1920px);
  }
}

.translated {
  height: vw(864px);
  transform: translateY(-108px);

  & video {
    overflow: hidden;
  }
}

.overlay {
  background-color: var(--backgroundmedium);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
