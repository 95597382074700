@import '~#/theme/vw';

@import '~#/theme/main';

@keyframes fadein {
  0% { right: vw(-1260px); }
  100% { right: 0; }
}

@keyframes fadeinrtl {
  0% { left: vw(-1024px); }
  100% { left: 0; }
}

.snackbar {
  align-items: center;
  backdrop-filter: blur(64px);
  background: var(--alternativebackgroundlow);
  border-radius: 16px;
  color: var(--coloronbackground);
  display: flex;
  flex-shrink: 0;
  max-height: vw(84px);
  padding: vw(32px);
  position: relative;
  transition: all var(--hidenotificationtime) ease;
  width: vw(960px);
  z-index: 100;

  &.portrait {
    max-height: vw(222px);
  }

  &.landscape {
    max-height: vw(125px);
  }

  &.square {
    max-height: vw(148px);
  }
}

.new {
  &.ltr {
    animation: fadein var(--shownotificationtime) forwards;
  }

  &.rtl {
    animation: fadeinrtl var(--shownotificationtime) forwards;
  }
}

.hide-animation {
  max-height: 0;
  padding: 0 vw(32px);

  .content,
  &.image-container {
    display: none;
  }
}

.content {
  align-items: center;
  display: flex;
  flex: 1 0 0;
}

.image-container {
  align-items: center;
  display: flex;
  height: vw(60px);
  justify-content: center;
  width: vw(60px);

  &.ltr {
    margin: 0 vw(20px) 0 0;
  }

  &.rtl {
    margin: 0 0 0 vw(20px);
  }

  &.portrait {
    height: vw(222px);
    width: vw(148px);
  }

  &.landscape {
    height: vw(125px);
    width: vw(220px);
  }
}

.image {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;
  padding: 11.25px;
  width: vw(60px);

  &.portrait {
    height: vw(222px);
    width: vw(148px);
  }

  &.landscape {
    height: vw(125px);
    width: vw(220px);
  }
}

.text-container {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: var(--border-radius-rounded-lg, 12px);
}

.title {
  align-self: stretch;
  font-size: vw(24px);
  font-style: normal;
  font-weight: 500;
  line-height: vw(24px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  align-self: stretch;
  font-size: vw(24px);
  font-style: normal;
  font-weight: 400;
  line-height: vw(24px);
  overflow: hidden;
  text-overflow: ellipsis;
}
