@import '~#/theme/main';

.background {
  @include bottom-align-container;

  background-repeat: no-repeat;
  background-size: cover;
  height: inherit;

  img {
    min-width: 100vw;
    object-fit: cover;
  }
}

.content-container {
  @include bottom-align-item;

  background: linear-gradient(
      359.06deg,
      var(--background) 0.85%,
      var(--background) 15.85%,
      var(--backgroundmedium) 33.21%,
      var(--backgroundlow) 49%,
      var(--backgroundlow) 86.44%
    ),
    linear-gradient(
      269.63deg,
      rgba(0, 0, 0, 0) 49.15%,
      var(--backgroundmedium) 58.26%,
      var(--backgroundhigh) 73.03%,
      var(--background) 99.69%
    );
  box-sizing: border-box;
  height: 100%;
  padding: vw(299px) 0 0 vw(80px);
  width: 100%;

  .button {
    left: 5vw;
    line-height: normal;
    top: 20vh;

    button {
      font-size: vw(22px);
      height: vw(64px);

      min-width: vw(192px);
      border-radius: vw(70px);
    }
  }
}

.title {
  color: var(--coloronaccent);
  font-size: vw(48px);
  font-weight: 600;
  line-height: vw(48px);
  margin-bottom: vw(24px);
}

.metadata {
  color: var(--alternativetext);
  font-size: vw(22px);
  font-weight: 300;
  line-height: vw(22px);
  margin-bottom: vw(24px);
}

.description {
  color: var(--alternativetext);
  font-size: vw(22px);
  font-weight: 300;
  line-height: vw(22px);
  margin-bottom: vw(32px);
  max-width: vw(702px);
}
