@import '~#/theme/main';

.background {

  @include bottom-align-container;

  background-repeat: no-repeat;
  background-size: cover;
  height: inherit;
}

.content-container {
  background:
    linear-gradient(
      330deg,
      rgba(9, 13, 17, 0) 49.15%,
      rgba(9, 13, 17, .4) 58.26%,
      rgba(9, 13, 17, .7) 73.03%,
      rgba(9, 13, 17, .85) 99.69%
    ),
    linear-gradient(
      360deg,
      rgba(0, 0, 0, 1) .85%,
      rgba(9, 13, 17, .4) 33.21%,
      rgba(9, 13, 17, .1) 49%,
      rgba(9, 13, 17, .1) 86.44%
    );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .content {
    padding: vw(20px) vw(88px) vw(77px);
  }

  .button {
    font-size: vw(30px);
    height: vw(35px);
    left: 5vw;
    line-height: normal;
    padding-top: vw(10px);
  }
}

.title {
  color: $font-color;
  font-size: vw(50px);
  height: vw(79px);
  line-height: normal;
  margin-top: vw(50px);
  padding: vw(30px) 0;
  text-shadow: $text-shadow;
}

.channel-logo {
  display: inline-block;
  vertical-align: middle;
  width: vw(200px);

  img {
    vertical-align: middle;
    width: vw(200px);
  }
}

.channel-title {
  display: inline-block;
  vertical-align: middle;
}

.progress {
  background: var(--alternativebackground);
  bottom: 0;
  height: vw(20px);
  left: 0;
  position: absolute;
  width: 100%;

  span {
    background: var(--coloronbackground);
    display: inline-block;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

.program-title {
  font-size: vw(24px);
  padding: vw(10px) 0;
}

.program-detail {
  font-size: vw(20px);
  padding: vw(10px) 0;
}
