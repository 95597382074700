@import '~#/theme/base';

.viewport {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
}

.page-content {
  padding: vw(20px);
  padding-left: vw(40px);
}

.failed-page-content {
  font-size: vw(30px);
  padding: vw(150px);

  &.ltr {
    padding-left: vw(100px);
  }

  &.rtl {
    padding-right: vw(100px);
  }
}

.tvkeyboardparent {
  height: 100vh;
}

.keyboardparent {
  background: red;
  display: block;
  float: left;
  height: 100%;
  text-align: center;
  width: 50%;
}

.keyboardcontent {
  background: #ff4848;
  height: 60%;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.authenticationparent {
  background: blue;
  display: block;
  float: left;
  height: 100%;
  text-align: center;
  width: 50%;
}

.authenticationcontent {
  background: rgb(57, 57, 255);
  height: 70%;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.inputtext {
  width: 50%;
}

.keyboardlayout {
  background-color: salmon;
  position: relative;
  width: vw(470px);
}

.keyboardbuttoncontainer {
  // background-color: seagreen;
  float: left;
  height: vw(70px);
  width: vw(70px);
}

.layout {
  background-color: red;
}

.buttoncontainer {
  display: default;
}

.keyboardbutton {
  background: rgba(60, 60, 100, .5);
  border: 0;
  border-radius: vw(1px);
  color: #fff;
  cursor: pointer;
  font-size: vw(18px);
  height: 98%;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98%;

  :focus {
    outline: 0;
  }

  :hover {
    background: rgba(100, 100, 140, .4);
  }
}

.keyboardbuttonfocused {
  background: rgba(120, 120, 200, .5);

  // color: $metadata-blue;
  color: var(--accent);
}

.keyboardbuttonactive {
  color: #0a6ebd;
}
