@import '~#/theme/main';

.background {
  background: var(--backgroundhigh);
  position: fixed;
  z-index: 101;
  width: vw(1920px);
  height: vw(1080px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: vw(828px);
  height: fit-content;
  padding: vw(24px);

  border-radius: vw(42px);
  background: #02242d;

  &.container-light-theme {
    background: #a4c0c9;
  }
}

.title {
  color: var(--coloronbackground);
  text-align: center;
  font-size: vw(26px);
  font-weight: 300;
  line-height: vw(31px);
  margin-bottom: vw(24px);
}

.instructions {
  color: var(--coloronbackground);
  text-align: center;
  font-size: vw(24px);
  font-weight: 300;
  line-height: vw(24px);
  white-space: pre-wrap;
  margin-bottom: vw(24px);
}

.qr-code {
  width: vw(109px);
  height: vw(109px);
  margin-bottom: vw(24px);

  img {
    width: vw(109px);
    height: vw(109px);
  }
}

.find-out-more {
  color: var(--coloronbackground);
  text-align: center;
  font-size: vw(22px);
  font-weight: 200;
  line-height: vw(27px);
  white-space: pre-wrap;
  margin-bottom: vw(24px);
}

.done-button {
  button {
    font-size: vw(22px);
    height: vw(64px);

    min-width: vw(192px);
    border-radius: vw(70px);
  }
}
