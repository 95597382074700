@import '~#/theme/main';

$details-text-color: $rgb-235;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .title {

    @include big-title-font-size;

    color: $details-text-color;
    font-size: vw(48px);
    font-weight: bold;
  }

  .description {
    font-size: vw(33px);
    line-height: 1.36;
  }
}
