@import '~#/theme/main';

.background {
  position: fixed;
  z-index: 1;
  width: vw(1920px);
  height: vw(1080px);
  display: flex;
  justify-content: flex-end;
}

.outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: vw(462px);
  height: fit-content;
  padding: vw(18px);

  border-radius: vw(36px);
  background: #02242d;
  box-shadow: vw(-17px) vw(18px) vw(36.3px) vw(29px) rgba(0, 0, 0, 0.25);
  margin-right: vw(47px);
  margin-top: vw(41px);

  &.light-theme {
    background-color: #467b80;
  }

  &.manual-rotation {
    width: vw(562px);
    padding: vw(25px);
    border-radius: vw(23px);
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  width: vw(444px);
  margin-bottom: vw(12px);
}

.info-icon {
  width: vw(39px);
  height: vw(39px);

  &.light-theme {
    svg {
      path {
        fill: var(--coloronaccent);
      }
    }
  }
}

.logo {
  width: vw(92px);
  height: vw(45px);
  display: flex;
  align-items: center;

  img {
    width: vw(92px);
    height: vw(21px);
  }
}

.horizontal-container {
  display: flex;
  align-items: center;
}

.left-toggle-button,
.right-toggle-button {
  button {
    width: vw(44px);
    height: vw(44px);
    padding: 0;
    border-radius: vw(30px);

    span {
      width: vw(44px);
      height: vw(40px);
      padding: 0;

      svg {
        width: vw(15px);
        height: vw(15px);
      }
    }
  }
}

.right-toggle-button {
  button {
    span {
      width: vw(40px);

      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.left-toggle-button {
  button {
    span {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
}

.inner-container {
  display: flex;
  flex-direction: column;

  width: vw(412px);
  min-height: vw(206px);
  padding: vw(25px);

  border-radius: vw(36px);
  background: rgba(255, 255, 255, 0.06);

  &.audio-only {
    min-height: auto;
  }

  &.manual-rotation {
    width: vw(386px);
    padding: vw(16px);
    min-height: vw(121px);
    border-radius: vw(17px);
    margin-bottom: vw(15px);
    margin-left: vw(26px);
    margin-right: vw(26px);
  }
}

.tips-counter {
  font-size: vw(20px);
  font-weight: 400;
  line-height: vw(25px);
  margin-bottom: vw(32px);
  color: var(--alternativetext);
}

.title {
  color: var(--coloronbackground);
  text-align: left;
  font-size: vw(26px);
  font-weight: 600;
  line-height: vw(31px);
  letter-spacing: vw(0.52px);
  margin-bottom: vw(15px);
}

.body-text {
  color: var(--coloronbackground);
  text-align: left;
  font-size: vw(20px);
  font-weight: 400;
  line-height: vw(25px);
  white-space: pre-wrap;
  margin-bottom: 0;
}

.buttons-container {
  display: flex;
}

.close-button,
.action-button {
  button {
    font-size: vw(20px);
    height: vw(49px);

    min-width: vw(147px);
    border-radius: vw(53px);

    span {
      padding: 0;
    }
  }
}

.close-button {
  margin-right: vw(12px);
}
