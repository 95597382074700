@import '~#/theme/main';

.name {
  font-size: vw(22px);
  line-height: vw(22px);
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.role {
  color: var(--coloronbackgroundmedium);
  font-size: vw(18px);
  line-height: vw(22px);
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
