@import '~#/theme/main';

$details-text-color: $rgb-235;

.container {
  box-sizing: border-box;
  display: inline-block;

  .link {
    height: 100%;
    position: relative;
    width: 100%;

    .background {
      background-repeat: no-repeat;
      background-size: cover;
      border: vw(3px) solid transparent;
      border-radius: vw(16px);
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;

      @include browser($legacy-devices) {
        border-radius: 12px !important;
      }

      &.background-focused {
        border: vw(3px) solid var(--accent);
      }

      &.background-focused .click-area {
        position: absolute;
        top: 0;
      }

      .view-all {
        font-size: 26px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 35%;
        width: 100%;
      }

      &.view-all-background {
        background-color: #303030;
        border-radius: vw(19px);
      }

      .logo-image {
        object-fit: contain;
      }

      .content-container {
        background-color: $grey-55;
        border-radius: 0 0 vw(15px) vw(15px);
        bottom: vw(2px);
        color: var(--coloronbackground);
        height: auto;
        left: vw(2px);
        min-height: vw(32px);
        position: absolute;

        .title {
          color: white;
          box-sizing: border-box;
          font-size: vw(26px);
          font-weight: 300;
          height: vw(75px);
          line-height: vw(40px);
          min-height: vw(32px);
          overflow: hidden;
          padding: vw(16px);
          text-overflow: ellipsis;
          text-shadow: $text-shadow;
          white-space: nowrap;
          width: 100%;

          &.title-continue-watching {
            padding: vw(27px);
          }
        }
      }

      .content-container-focused {
        @extend .content-container;

        background-color: var(--accent);
        color: var(--coloronaccent);
      }
    }
  }
}

.audio-only-mode-badge {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: vw(12px);
  color: white;
  display: flex;
  font-size: vw(19px);
  line-height: vw(19px);
  padding: 5.89px 8.84px;
  position: absolute;
  right: vw(4px);
  top: vw(3px);

  svg {
    margin-left: vw(8px);
    width: vw(25px);
    height: vw(25px);
  }
}
