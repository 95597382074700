.container {
  height: 80vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;

  &.hide {
    display: none;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &::after {
    background:
      linear-gradient(
        359.06deg,
        var(--background) .85%,
        var(--background) 15.85%,
        var(--backgroundmedium) 33.21%,
        var(--backgroundlow) 49%,
        var(--backgroundlow) 86.44%
      ),
      linear-gradient(
        269.63deg,
        rgba(0, 0, 0, 0) 49.15%,
        var(--backgroundmedium) 58.26%,
        var(--backgroundhigh) 73.03%,
        var(--background) 99.69%
      );
    content: '';
    display: block;
    height: 101%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
