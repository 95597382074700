@import '~#/containers/App/tvApp';

.root {

  @extend .root;

  background-color: rgb(32, 32, 32);
  height: 100%;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.splash-container {
  font-size: vw(20px);
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);

  > div {
    padding: vw(10px);
  }
}
