@import '~#/theme/vw';

$base-font-size: vw(16px);
$base-unit-in-px: vw(8px);
$base-unit: calc($base-unit-in-px / $base-font-size) * 1rem;
$border-width: calc($base-unit / 4);
$header-base: 3 * $base-unit;
$header-height: 8 * $base-unit;

@function size-without-border($size: 0) {

  @return calc(100% - 2 * #{$size});
}

@mixin square($size: $base-unit) {
  height: $size;
  width: $size;
}

@mixin big-title-font-size {
  font-size: 1.4em;
  line-height: 1.4em;
}

@mixin title-font-size {
  font-size: vw(36px);
  line-height: vw(36px);
}

@mixin subtitle-font-size {
  font-size: vw(32px);
  line-height: vw(34px);
}

@mixin description-font-size {
  font-size: 2em;
  line-height: 1.2em;
}
