@import '~#/theme/main';

$title-size: vw(35px);

.logo-container {
  align-items: center;
  display: flex;
  height: 100%;
}

.logo-title {
  display: table-cell;
  font-size: $title-size;
  height: $title-size;
  padding-left: vw(20px);
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.logo {
  width: vw(50px);
}

.logo-link {
  height: vw(50px);
  margin-bottom: auto;
  margin-top: auto;
}

.user-control {
  @include responsive(medium-screens) {
    right: vw(96px);
    top: vw(26px);
  }

  position: absolute;
  right: vw(40px);
}

.container {
  @include display-table;

  box-sizing: border-box;
  padding: 0 $header-base;
  width: 100%;
  z-index: $header-container-z-index;

  // stylelint-disable-next-line selector-class-pattern
  :global(.triState) button {
    &::before {
      @include pseudo;

      height: 70%; // Tune this to match the design
      width: size-without-border(10px);
    }
  }
}

.menu {
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-items-container {
  align-items: center;
  display: flex;
  padding: 0;
  width: 100%;
}

.main-menu {
  background: var(--backgroundlow);
  border-radius: vw(70px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  max-width: fit-content;
  overflow: hidden;
  height: vw(64px);
  padding: vw(8px);

  @include browser($legacy-devices) {
    width: vw(1200px);
  }
}

@keyframes highlight {
  from {
    filter: brightness(1);
  }

  to {
    filter: brightness(1.2);
  }
}

.sec-menu {
  background: var(--backgroundlow);
  border-radius: vw(70px);
  height: vw(64px);
  padding: vw(8px);

  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: end;
  text-align: right;
  width: auto;

  @include browser($legacy-devices) {
    width: fit-content;
  }

  &.ltr {
    float: right;
  }

  &.rtl {
    float: left;
  }
}

.title {
  bottom: 20px;
  display: inline-block;
  font-size: $title-size;
  height: 19px;
  margin-left: 30px;
  padding: 10px;
  position: relative;
  text-transform: uppercase;
}

.logo {
  width: vw(308px);
  height: vw(62px);
}
