@import '~#/components/Item/AssetItem/assetItem';

@import '~#/theme/vw';

.background {
  background-position: center;
  background-size: contain;

  .content-container {
    .title {
      font-size: vw(20px);
    }
  }
}
