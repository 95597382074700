@import '~#/theme/colors';

.root {
  background-color: var(--background);
  color: var(--coloronbackground);
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.root-transparent {
  background: transparent;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

:global(.link) {
  color: $rgb-150;
  text-decoration: none;
}

:global(.link:hover) {
  text-decoration: underline;
}

// Add platform specific handling here

.player {
  &.root {
    background-color: transparent;
  }
}

// Error Popup Styles
.error-popup-inner {
  background-color: #c9c9c933;
  height: 40vh;
  left: 50%;
  padding: 5%;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
}
