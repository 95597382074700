@import '~#/theme/main';

.info-banner-container {
  margin-top: vw(80px);
}

.background {
  border-radius: vw(32px);
  overflow: hidden;
  border: vw(3px) solid transparent;
  background-color: #062025;

  &.background-light-theme {
    background-color: #a4c0c9;
  }
}

.background-focused {
  border: vw(3px) solid var(--accent);
}

.content-container {
  width: vw(1760px);
  height: vw(740px);

  &.data-banner {
    height: vw(604px);
  }
}

.copy-container {
  width: vw(1760px);
  height: vw(740px);
  position: relative;
  background: linear-gradient(90deg, #062025 vw(823px), transparent vw(1098px));

  &.copy-light-theme {
    background: linear-gradient(
      90deg,
      #a4c0c9 vw(823px),
      transparent vw(1098px)
    );
  }

  .button {
    position: absolute;
    top: vw(602px);
    left: vw(120px);

    button {
      font-size: vw(22px);
      height: vw(64px);

      min-width: vw(192px);
      border-radius: vw(70px);
    }
  }
}

.title {
  font-size: vw(40px);
  height: vw(100px);
  width: vw(620px);
  max-height: vw(100px);
  max-width: vw(620px);
  text-overflow: ellipsis;
  line-height: vw(50px);
  font-weight: 500;

  position: absolute;
  top: vw(250px);
  left: vw(120px);
}

.body {
  font-size: vw(22px);
  height: vw(117px);
  width: vw(620px);
  max-height: vw(117px);
  max-width: vw(620px);
  text-overflow: ellipsis;
  line-height: vw(27.5px);
  font-weight: 200;

  position: absolute;
  top: vw(409px);
  left: vw(120px);
  white-space: pre-wrap;
}

.background-image {
  height: vw(740px);
  float: right;
}

.logo {
  position: absolute;
  left: vw(120px);
  top: vw(105px);
  width: vw(309px);
  height: vw(65px);
}

.data-container {
  color: white;
  position: absolute;
  left: vw(786px);
  display: flex;
  flex-direction: column;
  padding-top: vw(105px);

  &.data-banner {
    left: vw(108px);
    padding-top: vw(83px);
  }
}

.data-logo {
  margin-bottom: vw(20px);

  svg {
    width: vw(175px);
  }

  &.logo-light-theme {
    svg {
      path:not([id='logo-leaf']) {
        fill: var(--coloronaccent);
      }
    }
  }
}

.data-panel {
  display: flex;
  flex-direction: column;

  width: vw(770px);
  height: vw(390px);
  background-color: #173236;
  border-radius: vw(33px);

  padding: vw(24px);

  &.data-panel-light-theme {
    background-color: #467b80;
  }
}

.data-title {
  font-size: vw(40px);
  font-weight: 500;
  line-height: vw(50px);
  margin-bottom: vw(14px);
}

.data-subtitle {
  font-size: vw(24px);
  font-weight: 400;
  line-height: vw(24px);
  margin-bottom: vw(40px);
}

.data-items-container {
  display: flex;
  justify-content: space-between;
}

.data-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: vw(210px);
  height: vw(233px);
}

.usage-circular-bar {
  width: vw(96px);
  height: vw(96px);
  margin-bottom: vw(10px);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: vw(96px);
  background-color: rgba(255, 255, 255, 0.07);
  line-height: 0;
  overflow: hidden;
  position: relative;

  .first-quartile,
  .second-quartile,
  .third-quartile,
  .fourth-quartile {
    position: absolute;
    width: vw(96px);
    height: vw(96px);
    background-color: var(--accent);
    left: 50%;
    top: 50%;
    transform-origin: 0 0;
  }

  .inner-circle {
    background-color: #173236;
    width: vw(88px);
    height: vw(88px);
    border-radius: vw(88px);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;

    &.inner-circle-light-theme {
      background-color: #467b80;
    }

    .measurement-value {
      font-size: vw(20px);
      font-weight: 400;
    }

    .unit-of-measurement {
      font-size: vw(16px);
      font-weight: 200;
    }
  }
}

.usage-circular-bar-subtitle {
  width: vw(133px);
  height: vw(54px);
  margin-bottom: vw(10px);

  font-size: vw(16px);
  font-weight: 200;
  line-height: vw(18px);
  letter-spacing: vw(1.28px);
  text-transform: uppercase;
  text-align: center;
}

.usage-energy-savings-label {
  color: #a3a3a3;
  font-size: vw(18px);
  font-weight: 200;
  line-height: vw(22px);
  margin-bottom: vw(23px);

  &.label-light-theme {
    color: white;
  }
}

.usage-energy-savings-bar {
  width: vw(193px);
  height: vw(14px);
  border-radius: vw(58px);
  background: linear-gradient(
    90deg,
    rgba(255, 76, 0, 0.2) 12.84%,
    rgba(255, 229, 0, 0.2) 40.44%,
    rgba(49, 213, 114, 0.2) 75.11%,
    rgba(49, 114, 213, 0.2) 98.06%
  );
  position: relative;

  .progress {
    width: vw(63px);
    height: vw(14px);
    border-radius: vw(58px);
    background: linear-gradient(
      90deg,
      #ff4c00 4.12%,
      #ffe500 102.22%,
      #31d572 225.91%,
      #3172d5 304.73%
    );
    position: absolute;

    &.moderate {
      left: 33%;
      background: linear-gradient(
        90deg,
        #ff4c00 -97.93%,
        #ffe500 -0.09%,
        #31d572 123.27%,
        #3172d5 201.89%
      );
    }

    &.excellent {
      right: 0;
      background: linear-gradient(
        90deg,
        #ff4c00 -163.7%,
        #ffe500 -78.98%,
        #31d572 27.46%,
        #3172d5 97.92%
      );
    }
  }
}

.achievements-container {
  color: white;
  position: absolute;
  left: vw(970px);

  display: flex;
  flex-direction: column;

  padding-top: vw(83px);
}

.achievements-panel {
  width: vw(645px);
  height: vw(298px);
  padding: vw(24px);

  display: flex;
  flex-direction: column;

  border-radius: vw(33px);
  background-color: #173236;

  &.light-theme {
    background-color: #467b80;
  }
}

.achievements-title {
  font-size: vw(26px);
  font-weight: 300;
  line-height: vw(31px);
  margin-bottom: vw(28px);
}

.latest-achievements-container {
  border-radius: vw(84px);
  background-color: #23474c;

  width: vw(582px);
  min-height: vw(122px);

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: vw(12px);

  &.light-theme {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.badge {
  width: vw(122px);
  height: vw(122px);
}

.badge-description {
  text-align: center;
  font-size: vw(24px);
  font-weight: 400;
  line-height: vw(24px);

  display: flex;
  flex-direction: column;

  width: vw(159px);

  span {
    font-size: vw(20px);
    font-weight: 200;
    line-height: vw(20px);
    margin-top: vw(10px);
  }
}

.reward {
  text-align: center;
  font-size: vw(20px);
  font-weight: 400;
  line-height: 125%;

  display: flex;
  flex-direction: column;

  width: vw(155px);
  margin-right: vw(20px);

  span {
    font-size: vw(47px);
    line-height: vw(58px);
  }
}

.more-achievements {
  display: flex;
  align-items: center;
  margin-top: vw(18px);
  padding-left: vw(24px);

  span {
    font-size: vw(20px);
    font-weight: 200;
    line-height: vw(20px);
    margin-left: vw(24px);
  }
}

.badges-batch {
  height: vw(65px);
  width: vw(164px);
}

.achievements-buttons {
  display: flex;
  justify-content: space-between;
  height: vw(64px);
  margin-top: vw(29px);
}

.achievement-button {
  button {
    font-size: vw(22px);
    height: vw(64px);

    min-width: vw(334px);
    border-radius: vw(70px);
  }
}

.share-button {
  opacity: 0.5;
}

.share-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: vw(-6px);
}

.share-icon {
  width: vw(38px);
  height: vw(38px);
  margin-right: vw(8px);
}
